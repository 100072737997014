<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium">Show Stats</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex justify-between">
        <div v-if="! hasFilledMetrics">
          -
        </div>
        <loading-icon v-if="working" class="h-2" />
        <div v-else class="space-y-6 w-full">
          <template v-for="(metric, index) in originalStats">
            <div v-if="metric.value" :key="`metric-${index}`" class="space-y-1">
              <div class="flex items-center space-x-3">
                <p class="font-light">{{ metric.label }}</p>
                <div class="w-5.5">
                  <template v-if="metric.verified">
                    <img src="/images/verified_badge.png" class="w-5.5 h-5.5" alt="">
                  </template>
                  <template v-else-if="metric.requested">
                    <span v-tooltip="'Waiting for the Guestio Staff to confirm that this number is correct'">
                      <img src="/images/pending_verified_badge.png" class="w-5.5 h-5.5" alt="">
                    </span>
                  </template>
                </div>
              </div>
              <p class="text-xl text-blue-800">{{ metric.value }}</p>
            </div>
          </template>
        </div>
        <div>
          <Edit @edit="edit = true"/>
        </div>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="statsForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(updateStats)" method="post" class="space-y-8">
            <div
              v-for="(stat, i) in form.stats" :key="`metric-${i}`"
              class="border-guestio-gray-200"
              :class="{'border-b pb-8': [0, 1].includes(i)}"
            >
              <div class="flex w-full space-x-2">
                <div class="flex-1">
                  <ValidationProvider
                    mode="lazy"
                    rules="max:255"
                    :vid="`stats.${i}.label`"
                    :name="`Show Stats ${i} Label`"
                    v-slot="{ errors }"
                    tag="div"
                  >
                    <label class="block pl-4 text-black">Label</label>
                    <div class="mt-2">
                      <div class="rounded-full shadow-sm">
                        <label :for="`stat-${i}-label`" class="sr-only">{{ `Stat #${i} Label` }}</label>
                        <input :name="`stat-${i}-label`" :id="`stat-${i}-label`" type="text" class="py-4 px-4 rounded-full bg-white border border-guestio-gray-200 w-full" v-model="stat.label" placeholder="eg. Monthly listeners" />
                      </div>
                      <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>

                <div class="flex-1">
                  <ValidationProvider
                    mode="lazy"
                    rules="max:255"
                    :vid="`stats.${i}.value`"
                    :name="`Show Stats ${i} Value`"
                    v-slot="{ errors }"
                    tag="div"
                  >
                    <label class="block pl-4 text-black">Value</label>
                    <div class="mt-2">
                      <div class="rounded-full shadow-sm">
                        <label :for="`stat-${i}-value`" class="sr-only">{{ `Stat #${i} Value` }}</label>
                        <input
                        :name="`stat-${i}-value`"
                        :id="`stat-${i}-value`"
                        type="text"
                        class="py-4 px-4 rounded-full bg-white border border-guestio-gray-200 w-full disabled:bg-gray-50"
                        v-model="stat.value"
                        placeholder="eg. +600,000"
                      />
                      </div>
                      <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                    </div>
                  </ValidationProvider>
                </div>

                <div v-if="stat" class="w-5.5 flex-shrink-0 flex items-center h-14 self-end">
                  <template v-if="stat.verified && !needsToVerify(stat)">
                    <img src="/images/verified_badge.png" class="w-5.5 h-5.5" alt="">
                  </template>
                  <template v-else-if="stat.upload && stat.requested">
                    <span v-tooltip="'Waiting for the Guestio Staff to confirm that this number is correct'">
                      <img src="/images/pending_verified_badge.png" class="w-5.5 h-5.5" alt="">
                    </span>
                  </template>
                </div>
              </div>

              <div v-if="!stat.verified || needsToVerify(stat)" class="mt-4">
                <template v-if="!stat.upload">
                  <proof-upload-dialog
                    v-if="stat.label.length"
                    @selected="$set(stat, 'upload', $event)"
                    class="ml-4 text-sm text-violet flex items-center space-x-2 underline"
                  >
                    <UploadArrowIcon class="w-6 h-6" />
                    <span>Upload Screenshot</span>
                  </proof-upload-dialog>
                </template>

                <template v-else>
                  <div class="flex items-center justify-between border border-guestio-gray-200 rounded-lg py-3 px-5 mr-7">
                    <div>
                      <proof-details-dialog :upload="stat.upload">
                        <span class="text-sm text-violet">
                          {{ stat.upload.file_name }}
                        </span>

                        <span v-if="stat.upload.size" class="text-sm text-gray-500">
                          ({{ bytesToSize(stat.upload.size) }})
                        </span>
                      </proof-details-dialog>
                    </div>

                    <div class="space-x-4 flex items-center">
                      <button type="button" @click="cancelVerification(i)" v-tooltip="'Cancel verification request'">
                        <TrashAltIcon class="text-red-500 w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working" />
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import UploadArrowIcon from "@/components/svgs/UploadArrowIcon";
  import Edit from "@/components/shared/Edit";
  import ProofUploadDialog from "./ProofUploadDialog";
  import ProofDetailsDialog from "./ProofDetailsDialog";
  import TrashAltIcon from "@/components/svgs/TrashAltIcon";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    components: {
      Edit,
      UploadArrowIcon,
      ProofUploadDialog,
      ProofDetailsDialog,
      TrashAltIcon,
      SaveOrCancel,
    },

    props: {
      show: Object,
    },

    data() {
      return {
        working: false,
        edit: false,
        originalStats: [],
        form: {
          stats: [],
        }
      }
    },

    computed: {
      hasFilledMetrics() {
        return !! this.originalStats.filter(metric => metric.label && metric.value).length
      }
    },

    watch: {
      edit(edit) {
        if (edit) {
          while (this.form.stats.length < 3) {
            this.form.stats.push({
              label: '',
              value: '',
              verified: false,
              requested: false,
              upload: null,
            })
          }
        } else {
          this.fetchShowStats();
          this.fetchShowStats(true);
        }
      }
    },

    async mounted() {
      await this.fetchShowStats(true);
      await this.fetchShowStats();
    },

    methods: {
      async fetchShowStats(original = false) {
        await api.get(`/shows/${this.show.id}/stats`).then(({ data }) => {
          if (original) {
            this.originalStats = data.data;
          } else {
            this.form.stats = data.data;
          }
        })
      },

      updateStats() {
        this.working = true

        this.$store.dispatch('shows/updateProfileStats', {
          showId: this.show.id,
          stats: this.form.stats,
        })
          .then(() => {
            this.working = false
            this.edit = false
            // this.$emit('refresh');
            this.fetchShowStats(true);
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.statsForm.setErrors(error.response.data.errors)
            }
          })
      },

      bytesToSize(bytes) {
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB']

        if (bytes === 0) {
          return '0 B'
        }

        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
      },

      cancelVerification(index) {
        const stat = this.form.stats[index];

        if (stat.upload.uuid) {
          this.$store.dispatch('shows/cancelVerificationRequest', {
            showId: this.show.id,
            statId: stat.id,
          }).then(() => {
            this.form.stats[index].upload = null;
            this.fetchShowStats(true);
            // this.$emit('refresh');
          });
        } else {
          this.form.stats[index].upload = null;
        }
      },

      needsToVerify(stat) {
        const original = this.originalStats.find(s => s.id == stat.id);
        return original.value != stat.value || original.label != stat.label; 
      }
    }
  }
</script>
