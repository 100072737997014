<template>
  <portal v-if="type" to="modal">
    <div
      class="modal-container fixed bottom-0 inset-x-0 px-4 inset-0 pb-6 xl:p-0 sm:flex sm:items-center sm:justify-center pointer-events-none z-60"
      @click="close()">
      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0">
        <div v-if="modalOpened" class="fixed inset-0 transition-opacity pointer-events-auto">
          <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
        </div>
      </transition>

      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
        leave-active-class="ease-in duration-100"
        leave-class="opacity-100 translate-y-0 sm:scale-100"
        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
        <div
          v-if="modalOpened" @click.stop
          class="transition-all transform flex items-center justify-center w-full h-full pointer-events-auto overflow-y-auto"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div class="max-w-lg w-full max-h-screen bg-white rounded-xl px-12 py-10" @click.stop>
            <div>
              <h3 class="font-bold text-2xl text-blue-800">
                Enter URL
              </h3>
              <p class="mt-4">
                Enter url for your {{ type.name }}
              </p>
            </div>

            <div class="mt-4 sm:mt-6">
              <ValidationObserver ref="confirmForm" v-slot="{ handleSubmit, invalid }">
                <form @submit.prevent="handleSubmit(saveUrl)" method="post">
                  <div>
                    <label class="font-medium text-black">
                      {{ type.name }}{{ type.id === 1 ? ' (RSS)' : '' }} URL
                    </label>
                    <ValidationProvider
                      :rules="[1, 2].includes(type.id) ? 'required|url|max:255' : 'url|max:255'"
                      name="Url"
                      vid="url"
                      v-slot="{ errors }"
                    >
                      <div class="mt-2">
                        <input type="url" class="form-input text-black sm:text-lg bg-gray-50 w-full px-5 sm:py-3 rounded-full border-transparent focus:bg-white focus:shadow-3xl disabled:opacity-75" v-model="form.url" ref="urlField" :disabled="loading">
                      </div>
                      <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                    </ValidationProvider>
                  </div>

                  <div class="mt-4">
                    <button class="btn text-base bg-gray-600 flex justify-center w-full items-center disabled:opacity-50 h-12" type="submit" :disabled="loading || invalid">
                      <span v-if="loading">
                        <loading-icon class="h-2 px-3"/>
                      </span>
                      <span class="text-sm sm:text-xl" v-else>Save Type</span>
                    </button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>

          <div class="absolute top-0 right-0 mt-4 mr-4 z-50">
            <button @click.prevent="close()" type="button"
                    class="p-2 rounded-full text-white opacity-75 hover:opacity-100 focus:bg-gray-500 bg-gray-600 bg-opacity-50">
              <svg class="h-8 lg:h-12 xl:h-14" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                   viewBox="0 0 24 24" stroke="currentColor">
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
        </div>
      </transition>
    </div>
  </portal>
</template>

<script>
  export default {
    props: {
      type: Object,
    },

    data() {
      return {
        loading: false,
        modalOpened: false,
        form: {
          url: null,
        },
      }
    },

    watch: {
      modalOpened(modalOpened) {
        document.body.classList.toggle('overflow-hidden', modalOpened)

        this.$emit(modalOpened ? 'opened' : 'closed', this.form.url)

        if (modalOpened) {
          this.$nextTick().then(() => {
            this.$refs.urlField.focus()
          })
        }
      }
    },

    methods: {
      close() {
        this.modalOpened = false
        this.loading = false
      },

      open() {
        this.modalOpened = true
        this.loading = false
        this.form.url = null
      },

      handleEscape(e) {
        if (e.keyCode === 27) {
          this.close()
        }
      },

      saveUrl() {
        this.modalOpened = false
      }
    },

    mounted() {
      document.addEventListener('keyup', this.handleEscape)
    },

    beforeDestroy() {
      document.removeEventListener('keyup', this.handleEscape)
    }
  }
</script>
