<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0 mb-2">
      <span class="text-grayDark font-medium">Tags</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <div class="flex items-center flex-wrap -mx-1 -my-2">
          <span v-for="tag in show.tags" :key="`tag-${tag.value}`" class="p-1 mb-3">
            <span class="text-sm font-light text-white bg-purple-50 rounded-full px-4 py-2 whitespace-no-wrap">
              {{ tag.name }}
            </span>
          </span>
        </div>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="tagsForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <ValidationProvider name="Expertise" vid="tags" v-slot="{ errors }">
                <multiselect
                  v-model="form.tags"
                  tag-placeholder="Add this as new tag"
                  placeholder="Search or add a tag"
                  label="name"
                  track-by="name"
                  :options="tags"
                  :multiple="true"
                  :taggable="true"
                  @tag="addTag"
                  @search-change="searchChange"
                  @close="onClose"
                >
                  <template slot="option" slot-scope="props">
                    <span class="">{{ props.option.code == 'is-category' ? `${props.search} (Cannot add this tag. It is already a category)` : props.option.name || props.search }}</span>
                  </template>
                </multiselect>
                <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import Multiselect from 'vue-multiselect'
  import Edit from "@/components/shared/Edit";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    props: {
      show: Object,
    },

    components: {Multiselect,Edit,SaveOrCancel},

    data() {
      return {
        working: false,
        edit: false,
        form: {
          tags: this.show.tags,
        },
        tags: [],
        categories: [],
      }
    },

    watch: {
      edit(edit) {
        if (edit) return

        this.form.tags = this.show.tags
      }
    },

    mounted() {
      this.form.id = this.show.id;
    },

    methods: {
      addTag(newTag) {
        const tag = {
          name: newTag,
          code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
        }
        this.form.tags.push(tag)
        this.tags.push(tag)
      },

      update() {
        this.working = true

        this.$store.dispatch('shows/updateShow', this.form)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.tagsForm.setErrors(error.response.data.errors)
            }
          })
      },

      searchChange(query) {
        const index = this.categories.findIndex(cat => cat.name.toLowerCase() == query.toLowerCase())
        if (index != -1) {
          this.tags.push({name: query, code: 'is-category', $isDisabled: true })
        }
      },

      onClose() {
        let disabled = this.tags.filter(tag => '$isDisabled' in tag)
        disabled.forEach(element => this.tags.splice(this.tags.findIndex(e => e.name === element.name)));
      }
    },

    created() {
      api.get(`/tags`).then(({ data }) => {
        this.tags = []
        for (let key in data.data) {
          this.tags.push({name: data.data[key], code: key })
        }
      })

      api.get(`/categories?exclude=subcategories`).then(({ data }) => {
        if (data.success) {
          this.categories = data.data
        }
      })
    }
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
