<template>
  <div class="max-w-3xl mb-13">
    <div v-if="!show.id" class="mt-12 max-w-md mx-auto flex justify-center">
      <loading-icon class="h-10 w-10 text-pink-500" />
    </div>
    <div>
      <p class="text-grayDark mb-8">
        To set your Pro Stats section and show verified stats of your Show
        to Pro Users on your profile, enter your podcast name:
      </p>

      <RephonicSearch @loading="setLoading" @selected="setSelected" :deleted="deleted" :searchable="currentPodcastStats == null">
        <span
          slot="button"
          class="absolute right-0 pr-2 h-full flex items-center z-10"
        >
          <template v-if="!loading">
            <button
              v-if="!currentPodcastStats"
              type="button"
              class="add"
              :disabled="selected == null || loading || updatingPodcast"
              :class="
                selected
                  ? 'bg-gradient-to-r from-customPurple to-pink-300'
                  : 'bg-guestio-gray-300'
              "
              @click.prevent="selectPodcast"
            >
              Add
            </button>
            <button
              v-else
              @click.stop="deleteRephonicPodcast"
              class="rounded-full p-2 bg-guestio-gray-100"
            >
              <XIcon class="h-4 text-blue-800" />
            </button>
          </template>
        </span>
      </RephonicSearch>

      <div
        class="mt-9 flex justify-center"
        :class="{ 'flex-col': currentPodcastStats }"
      >
        <div v-if="currentPodcastStats">
          <p class="text-lg font-bold text-blue-800 pb-3">
            Podcast information
          </p>

          <RephonicStats :currentPodcastStats="currentPodcastStats" />
        </div>
        <loading-icon
          v-else-if="updatingPodcast"
          class="h-10 w-10 text-pink-500"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RephonicStats from "@/components/shared/RephonicStats";
import RephonicSearch from "@/components/shared/RephonicSearch";
import XIcon from "@/components/svgs/XIcon";

export default {
  components: { RephonicSearch, RephonicStats, XIcon },
  data() {
    return {
      loading: false,
      updatingPodcast: false,
      currentPodcastStats: null,
      selected: null,
      deleted: false,
    };
  },

  computed: {
    ...mapState({
      show: state => state.shows.show
    })
  },

  mounted() {
    const stats = this.show.rephonic_stats;
    if (stats) {
      this.currentPodcastStats = stats;
      this.selected = {
        title: stats.name,
        desc: `${stats.num_episodes} Episodes - ${stats.publisher_name}`
      };
    }
  },

  methods: {
    setLoading(loading) {
      this.loading = loading;
    },
    
    setSelected(selected) {
      this.selected = selected;
    },

    selectPodcast() {
      this.updatingPodcast = true;
      this.$store
        .dispatch("shows/updateRephonicStats", {
          showId: this.show.id,
          rephonic_id: this.selected.rephonicId
        })
        .then(response => {
          this.currentPodcastStats = response.data.stats;
          this.$store.commit("guestBooking/setShow", {
            rephonic_stats: this.currentPodcastStats
          });
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => (this.updatingPodcast = false));
    },

    deleteRephonicPodcast() {
      const params = { showId: this.show.id, rephonic_id: null };
      this.$store.dispatch("shows/updateRephonicStats", params).then(() => {
        this.currentPodcastStats = null;
        this.selected = null;
        this.deleted = true;
      });
    }
  }
};
</script>

<style>
.add {
  @apply py-2 px-14 rounded-full text-sm font-semibold text-white flex items-center focus:outline-none;
}
</style>
