<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium">Show Visibility</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <span class="font-semibold text-blue-800">{{ show.visible == 1 ? 'Public' : 'Private' }}</span>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="nameForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <ValidationProvider mode="lazy" rules="required" vid="visible" name="Visibility" v-slot="{ errors }">
                <div>
                  <div class="rounded-lg shadow-sm">
                    <label for="visible" class="sr-only">Visibility</label>
                    <select name="visible" id="visible" class="form-select guestio-form-input" v-model="form.visible">
                      <option value="0">Private</option>
                      <option value="1">Public</option>
                    </select>
                  </div>
                  <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import Edit from "@/components/shared/Edit";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    components: {Edit, SaveOrCancel},

    props: {
      show: Object,
    },

    data() {
      return {
        working: false,
        edit: false,
        form: {
          visible: this.show.visible,
        }
      }
    },

    watch: {
      edit(edit) {
        if (edit) return

        this.form.visible = this.show.visible
      }
    },

    mounted() {
      this.form.id = this.show.id;
    },

    methods: {
      update() {
        this.working = true

        this.$store.dispatch('shows/updateShow', this.form)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.nameForm.setErrors(error.response.data.errors)
            }
          })
      }
    }
  }
</script>
