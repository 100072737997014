<template>
  <div>
    <div class="flex flex-col md:flex-row md:justify-between">
      <div class="w-64 flex-shrink-0">
        <span class="text-grayDark font-medium">Show Type</span>
      </div>
      <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
        <div v-if="! edit" class="w-full flex items-center justify-between">
          <div>
            <ul class="space-y-2">
              <li v-for="(type, index) in show.showTypes" :key="index" class="font-semibold text-purple-50">
                {{ type.name }}
              </li>
            </ul>
          </div>
          <Edit @edit="edit = true"/>
        </div>
        <div v-else class="w-full">
          <ValidationObserver ref="typeForm" v-slot="{ handleSubmit }" tag="div">
            <form @submit.prevent="handleSubmit(update)" method="post">
              <div>
                <template v-if="showTypes.length">
                  <div>
                    <ValidationProvider rules="required" name="Show Type" vid="show_type" tag="div" class="flex flex-wrap">
                      <label v-for="(type, index) in showTypes" :key="index" class="cursor-pointer select-none flex items-center text-sm mr-6 mb-4">
                        <input v-model="form.types" :value="type.id" @change="openUrlDialog(type, $event.target.checked)" type="checkbox" class="form-checkbox h-5.5 w-5.5 text-pink-500">
                        <span class="ml-2">{{ type.name }}</span>
                      </label>
                    </ValidationProvider>
                  </div>
                </template>

                <template v-else>
                  No default types loaded.
                </template>
              </div>
              <div class="mt-4 flex items-center justify-end">
                <SaveOrCancel @cancel="edit = false" :working="working"/>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <div v-for="(type, index) in show.showTypes" :key="index" class="md:py-6 border-t border-gray-300">
        <show-type-url :show="show" :type="type" />
      </div>
    </div>

    <ShowTypeUrlDialog
      ref="urlDialog"
      :type="selectedType"
      @closed="closeUrlDialog"
    />
  </div>
</template>

<script>
  import Edit from "@/components/shared/Edit";
  import ShowTypeUrl from "./ShowTypeUrl";
  import ShowTypeUrlDialog from "./ShowTypeUrlDialog";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    components: {Edit, SaveOrCancel, ShowTypeUrl, ShowTypeUrlDialog},

    props: {
      show: Object,
    },

    data() {
      return {
        working: false,
        edit: false,
        form: {
          id: null,
          types: [],
          type_urls: {},
        },
        showTypes: [],
        selectedType: null,
      }
    },

    watch: {
      edit(edit) {
        if (edit) return

        this.form.platform_type = this.show.platform_type
      }
    },

    created() {
      this.fetchShowTypes()

      this.form.id = this.show.id
      this.form.types = this.show.showTypes.map(type => type.id)

      this.show.showTypes.forEach(type => {
        this.$set(this.form.type_urls, type.id, type.pivot.url)
      })
    },

    methods: {
      update() {
        this.working = true

        this.$store.dispatch('shows/updateShow', this.form)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.typeForm.setErrors(error.response.data.errors)
            }
          })
      },

      async fetchShowTypes() {
        const { data } = await this.$store.dispatch('shows/fetchShowTypes')

        this.showTypes = [...data.data]
      },

      getTypeById(id) {
        if (! this.showTypes.length) {
          return
        }

        return this.showTypes.find(type => type.id === id)
      },

      openUrlDialog(type, checked) {
        if (! checked) {
          return
        }

        this.selectedType = type
        this.$refs.urlDialog.open()
      },

      closeUrlDialog(url) {
        if (url) {
          this.$set(this.form.type_urls, this.selectedType.id, url)
          return
        }

        if (! [1, 2].includes(this.selectedType.id)) {
          // url required only for podcast and url
          return
        }

        const index = this.form.types.findIndex(id => id === this.selectedType.id)

        if (index === -1) {
          return
        }

        this.form.types.splice(index, 1)
        this.selectedType = null
      }
    }
  }
</script>
