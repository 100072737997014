<template>
  <modal :with-close-button="false">
    <slot />

    <template v-slot:content="{ close }">
      <div class="bg-white rounded-xl overflow-hidden shadow-xl transform transition-all sm:max-w-md sm:w-full relative" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="bg-white px-4 pt-6 pb-5 sm:px-8 sm:py-6 sm:pb-6">
          <button @click="close" type="button" class="absolute top-0 right-0 text-gray-500 p-1 rounded focus:shadow-outline mt-2 mr-3">
            <XIcon class="w-5 h-5" />
          </button>

          <div>
            <h3 class="font-bold text-blue-800">
              Screenshot
            </h3>
          </div>

          <div class="mt-6 uploader">
            <div class="relative border-dashed border-2 border-violet h-40 flex flex-col items-center justify-center overflow-hidden rounded-lg">
              <img :src="upload.url" class="h-40 w-full object-cover">
              <a :href="upload.url" target="_blank" class="block absolute inset-0"></a>
            </div>
          </div>

          <div v-if="upload.comments && upload.comments.length" class="mt-6">
            <label for="details" class="block text-sm font-bold text-blue-800">
              Additional comments
            </label>
            <div class="mt-3 h-32 overflow-y-scroll violet-scrollbar text-sm text-blue-800">
              {{ upload.comments }}
            </div>
          </div>

          <div class="mt-6 flex justify-center">
            <button
              type="button"
              class="rounded-full bg-indigo-gradiant text-white py-3 font-bold flex justify-center items-center uppercase px-16 disabled:opacity-50"
              @click="close"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/shared/Modal';
import XIcon from '@/components/svgs/XIcon';

export default {
  components: {
    Modal,
    XIcon,
  },

  props: {
    upload: {
      type: Object,
    }
  },

  methods: {
    //
  }
}
</script>
