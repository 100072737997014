<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium">
        {{ type.name }}{{ type.id === 1 ? ' (RSS)' : '' }} URL
      </span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <template v-if="type.pivot.url">
          <span class="font-semibold text-blue-800 w-48 xxl:w-full truncate">{{ type.pivot.url }}</span>
        </template>

        <template v-else>
          <span class="text-grayDark"><em>(no url added)</em></span>
        </template>

        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="websiteForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <ValidationProvider
                :rules="[1, 2].includes(type.id) ? 'required|url|max:255' : 'url|max:255'"
                vid="url"
                name="URL"
                v-slot="{ errors }"
              >
                <div>
                  <div class="rounded-lg shadow-sm">
                    <label for="url" class="sr-only">{{ type.name }} URL</label>
                    <input name="url" id="url" type="url" class="guestio-form-input" v-model="form.url" />
                  </div>
                  <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import Edit from "@/components/shared/Edit";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    components: {Edit, SaveOrCancel},

    props: {
      show: Object,
      type: Object,
    },

    data() {
      return {
        working: false,
        edit: false,
        form: {
          url: null,
        }
      }
    },

    computed: {
      type_urls() {
        const urls = {}

        this.show.showTypes.forEach(type => {
          urls[type.id] = type.pivot.url
        })

        return urls
      }
    },

    watch: {
      edit() {
        this.form.url = this.type.pivot.url
      }
    },

    methods: {
      update() {
        this.working = true

        const urls = {
          ...this.type_urls
        }

        urls[this.type.id] = this.form.url

        this.$store.dispatch('shows/updateShow', {
          id: this.show.id,
          types: this.show.showTypes.map(type => type.id),
          type_urls: urls
        })
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.websiteForm.setErrors(error.response.data.errors)
            }
          })
      }
    }
  }
</script>
