<template>
  <div>
    <div>
      <h3 class="text-3xl text-black font-bold">
        <a name="availability">Pricing</a>
      </h3>
      <p class="text-grayDark font-medium mt-2">Select pricing and time limits for interview booking requests.</p>
    </div>
    <ValidationObserver ref="pricesForm" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(update)" method="post">
        <div class="mt-12 w-8/12">
          <h4 class="block mb-5 font-semibold">Time Slots</h4>
          <div v-if="! form.prices.length" class="h-12 border border-dashed rounded-full flex items-center justify-center -mt-2">
            <span class="text-sm text-gray-600">No time slots added.</span>
          </div>
          <div v-else v-for="(slot, index) in form.prices" :key="`slot-${index}`" class="flex items-start mb-5">
            <div class="flex items-center">
              <div>
                <ValidationProvider
                  rules="required"
                  :vid="`prices.${index}.interview_duration`"
                  :name="`Time slot duration ${index}`"
                  v-slot="{ errors }"
                >
                  <div
                    class="bg-transparent rounded-full bg-gray-50 py-2 px-5 flex items-center "
                    :class="{'border border-red-500': errors.length}"
                  >
                    <ClockIcon class="mr-3 flex-shrink-0"/>
                    <select class="bg-transparent" v-model="slot.interview_duration">
                      <option
                        v-for="duration in availableInterviewDurations"
                        :value="duration"
                        :key="`duration-option-${duration}`"
                        :hidden="selectedDurations.includes(duration) && duration != slot.interview_duration"
                      >{{ duration }}</option>
                    </select>
                  </div>
                </ValidationProvider>
              </div>

              <div class="ml-2">
                <span>minutes</span>
              </div>
            </div>

            <div class="ml-6 xl:ml-12 flex items-center">
              <div>
                <ValidationProvider name="Price" :vid="`prices.${index}.price`" v-slot="{ errors }" rules="required|min_value:5">
                  <div
                    class="bg-transparent rounded-full bg-gray-50 py-2 px-5 flex items-center w-32"
                    :class="[{'border border-red-500': errors.length}]"
                  >
                    <DollarSignIcon class="mr-3 flex-shrink-0"/>
                    <input
                      id="price"
                      type="number"
                      min="5"
                      oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                      class="bg-transparent w-full"
                      v-model="slot.price"
                    >
                  </div>
                  <p class="form-error text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>

            <div class="ml-6">
              <button @click.prevent="removeInterviewDuration(index)" class="rounded-full bg-gray-50 h-10 w-10 flex justify-center items-center">
                <CrossIcon/>
              </button>
            </div>
          </div>

          <button @click.prevent="addInterviewDuration()" class="text-pink-500 underline mt-2 block">
            Add Time Slot +
          </button>
        </div>

        <div class="mt-4 flex items-center">
          <button type="submit" class="h-12 rounded-full px-10 py-3 bg-indigo-gradiant text-white" :class="{'opacity-50': working}" :disabled="working">
            <span v-if="working">
              <loading-icon class="h-2 px-6" />
            </span>
            <span v-else>Save Pricing</span>
          </button>

          <transition name="simple-fade" appear>
            <span v-if="saved" class="ml-4 text-green-600">
              Saved!
            </span>
          </transition>

          <transition name="simple-fade" appear>
            <span v-if="error.length" class="ml-4 text-red-600">
              {{ error }}
            </span>
          </transition>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import ClockIcon from "@/components/svgs/ClockIcon";
  import DollarSignIcon from "@/components/svgs/DollarSignIcon";
  import CrossIcon from "@/components/svgs/CrossIcon";
  export default {
    props: {
      show: Object,
    },

    components: {
      ClockIcon, DollarSignIcon, CrossIcon,
    },

    data() {
      return {
        working: false,
        edit: false,
        saved: false,
        error: '',
        form: {
          id: null,
          prices: [],
        }
      }
    },

    watch: {
      edit(edit) {
        if (edit) return

        this.form.visible = this.show.visible
      }
    },

    computed: {
      availableInterviewDurations() {
        return this.$store.getters['shows/availableInterviewDurations']
      },

      selectedDurations() {
        return this.form.prices.map(slot => slot.interview_duration)
      },
    },

    mounted() {
      this.form.id = this.show.id;
      this.form.prices = [...this.show.prices];
    },

    methods: {
      freeSlot(checked, index, price) {
        if (checked) {
          return this.form.prices[index].price = 0;
        }

        this.form.prices[index].price = price == 0 ? 10 : price;
      },

      addInterviewDuration() {
        this.form.prices.push({
          interview_duration: null,
          price: 10
        })
      },

      removeInterviewDuration(index) {
        this.form.prices.splice(index, 1)
      },

      async update() {
        let confirmed = await this.confirmLogin()

        if (! confirmed) {
          return
        }

        this.working = true

        this.$store.dispatch('shows/updateShow', this.form)
          .then(() => {
            this.working = false
            this.edit = false
            this.saved = true
            setTimeout(() => this.saved = false, 3000)
          })
          .catch(error => {
            this.working = false
            this.error = 'Please correct the form errors'
            if (error.response) {
              this.$refs.pricesForm.setErrors(error.response.data.errors)
            }
          })
      }
    }
  }
</script>
