<template>
  <div>
    <div v-if="! show.id" class="mt-12 max-w-md mx-auto flex justify-center">
      <loading-icon class="h-10 w-10 text-pink-500"/>
    </div>
    <div class="mt-12 max-w-3xl w-full">
      <div>
        <div class="md:py-6 border-b last:border-b-0 border-gray-300">
          <show-profile-url/>
        </div>
        <div class="md:py-6 border-b last:border-b-0 border-gray-300">
          <remove-branding :model="show" entity="show"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import ShowProfileUrl from '@/components/details/pro/ShowProfileUrl';
import RemoveBranding from '@/components/details/pro/RemoveBranding.vue';


  export default {
    components: {
      ShowProfileUrl,
      RemoveBranding
    },

    computed: {
      ...mapState({
        show: state => state.shows.show
      }),
    },
  }
</script>
