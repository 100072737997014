<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium">Social Media</span>
    </div>
    <div v-if="show.links" class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <div>
          <div v-if="show.links.social_media" class="mb-6 text-sm">
            <div class="mt-1">
              <span class="flex text-grayDark font-light mt-3 text-base" v-for="(link, index) in show.links.social_media" :key="`link-${index}`">
                <span class="items-center capitalize mr-2 text-blue-800 font-semibold">
                  {{index}}:
                </span>
                <span class="h-7 overflow-hidden break-all">{{ link ? link : '-' }}</span>
              </span>
            </div>
          </div>
        </div>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="linksForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div class="mb-6">
              <div>
                <label for="link-social_media" class="capitalize text-sm font-semibold">
                  Social media
                </label>
              </div>

              <div v-for="(link, index) in form.links.social_media" :key="`link-${index}`">
                <ValidationProvider :rules="socialMediaRules(index)" name="Link" :vid="form.links.social_media[index]" v-slot="{ errors }" class="w-full">
                  <span class="py-1 flex">
                    <p class="flex items-center capitalize font-medium mr-2 text-gray-500 text-sm">{{index}}</p>
                      <input :id="`link-social_media`" type="text" class="guestio-form-input" placeholder="Type or paste URL" v-model="form.links.social_media[index]">
                  </span>
                  <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>
            </div>

            <div class="mt-4 flex items-center justify-end">
              <button class="detail-cancel-button" :disabled="working" @click.prevent="edit = false">Cancel</button>
              <button type="submit" class="detail-save-button" :disabled="working">
                Save
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div v-else class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <loading-icon class="h-5 w-5 mr-4"/>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import Edit from "@/components/shared/Edit";

  export default {
    name: 'ShowSocialMediaLinks',

    components: { Edit },

    data() {
      return {
        working: false,
        edit: false,
        form: {
          links: {
            social_media: []
          },
        },
      }
    },

    watch: {
      edit(edit) {
        if (edit) return
        this.fetchShowLinks();
      }
    },

    computed: {
      ...mapState({
        show: state => state.shows.show,
      }),
    },

    mounted() {
      this.fetchShowLinks();
      this.form.id = this.show.id;
    },

    methods: {
      fetchShowLinks() {
        const params = {
          id: this.show.id,
          excludes: 'episodes_parsed,media_features_parsed'
        }
        this.$store.dispatch('shows/fetchShowLinks', params)
          .then(() => this.form.links = this.show.links)
      },

      getValidUrl(url = "") {
        let newUrl = window.decodeURIComponent(url);
        newUrl = newUrl.trim().replace(/\s/g, "");

        if(/^(:\/\/)/.test(newUrl)){
            return `http${newUrl}`;
        }
        if(!/^(f|ht)tps?:\/\//i.test(newUrl)){
            return `http://${newUrl}`;
        }

        return newUrl;
      },

      update() {
        this.working = true

        this.$store.dispatch('shows/updateShow', this.form)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.linksForm.setErrors(error.response.data.errors)
            }
          })
      },

      socialMediaRules(socialNetwork) {
        let hasDomain = `hasDomain:${socialNetwork}`;
        return `url${socialNetwork != 'website' ? '|' + hasDomain : ''}`;
      },
    }
  }
</script>
