<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium">Profile URL</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <span v-if="show.slug" class="text-blue-800 font-semibold">
          <router-link :to="`/shows/${show.slug}`" class="flex items-center space-x-1 hover:underline">
            <span>
              {{ frontendUrl }}/shows/<span class="font-bold">{{ show.slug }}</span>
            </span>
          </router-link>
        </span>
        <span v-else class="text-blue-800 font-semibold">
          <router-link :to="`/shows/${show.id}`" class="flex items-center space-x-1 hover:underline">
            <span>
              {{ frontendUrl }}/shows/{{ show.id }}
            </span>
          </router-link>
        </span>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="slugForm" v-slot="{ handleSubmit }">
          <form ref="form" @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <ValidationProvider ref="slugValidator" mode="lazy" rules="max:255" vid="slug" name="Profile URL" v-slot="{ errors }">
                <label for="slug" class="sr-only">Profile URL</label>
                <div @click="focusInput" class="flex items-center text-indigo-500 w-full rounded-full bg-gray-50 px-4 py-2 text-sm focus-within:shadow-3xl focus-within:border focus-within:border-indigo-500">
                  <LinkIcon  class="h-4 w-4"/>
                  <span class="ml-1">
                    {{ frontendUrl }}/shows/
                  </span>
                  <input ref="slugInput" @keyup="slugify" class="bg-transparent font-bold flex-1" type="text" v-model="form.slug">

                  <span v-if="slugAvailable" class="ml-2 shadow-md w-6 h-6 inline-block rounded-full bg-green-100 text-green-600 flex items-center justify-center">
                    <CheckIcon class="w-5 h-5" />
                  </span>

                  <span v-if="! slugAvailable" class="ml-2 shadow-md w-6 h-6 inline-block rounded-full bg-red-100 text-red-600 flex items-center justify-center">
                    <XIcon class="w-5 h-5"/>
                  </span>
                </div>
                <div v-if="errors.length" class="text-sm text-red-500 mt-1">
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import api from '@/api'
  import { debounce } from 'lodash'
  import Edit from "@/components/shared/Edit";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";
  import LinkIcon from '@/components/svgs/LinkIcon'
  import CheckIcon from '@/components/svgs/CheckIcon'
  import XIcon from '@/components/svgs/XIcon'

  export default {
    components: {Edit, SaveOrCancel, LinkIcon, CheckIcon, XIcon},

    data() {
      return {
        working: false,
        edit: false,
        form: {
          slug: null,
        },
        slugAvailable: true,
      }
    },

    watch: {
      edit(edit) {
        if (edit) {
          this.$nextTick().then(() => {
            this.$refs.slugInput.focus()
          })
          return
        }

        this.form.slug = this.show.slug
      },
    },

    mounted() {
      this.form.slug = this.show.slug
      this.form.id = this.show.id
    },

    methods: {
      slugify: debounce(function () {
        this.form.slug = this.form.slug.replace(/[^a-z0-9-]/gi, '')

        this.$refs.slugValidator.reset()

        api.post(`/shows/${this.show.id}/check-slug`, {
          slug: this.form.slug,
        }).then(() => {
          this.slugAvailable = true
        }).catch(() => {
          this.$refs.slugValidator.setErrors(['The url slug has already been taken.'])
          this.slugAvailable = false
        })
      }, 750),

      focusInput() {
        this.$refs.slugInput.focus()
      },

      update() {
        this.working = true

        this.$store.dispatch('shows/updateShow', this.form)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.slugForm.setErrors(error.response.data.errors)
            }
          })
      }
    },

    computed: {
      ...mapState({
        show: state => state.shows.show,
        user: state => state.auth.user
      }),

      frontendUrl() {
        return process.env.VUE_APP_URL
      }
    },
  }
</script>
