<template>
  <modal :with-close-button="false">
    <slot />

    <template v-slot:content="{ close }">
      <div class="bg-white rounded-xl overflow-hidden shadow-xl transform transition-all sm:max-w-md sm:w-full relative" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="bg-white px-4 pt-6 pb-5 sm:px-8 sm:py-6 sm:pb-6">
          <button @click="close" type="button" class="absolute top-0 right-0 text-gray-500 p-1 rounded focus:shadow-outline mt-2 mr-3">
            <XIcon class="w-5 h-5" />
          </button>

          <div>
            <div class="flex items-center space-x-4">
              <div class="w-9 h-9 rounded-full bg-violet bg-opacity-10 flex items-center justify-center">
                <UploadArrowIcon class="w-6 h-6 text-violet" />
              </div>
              <h3 class="font-bold text-blue-800">
                Upload screenshot to verify
              </h3>
            </div>
          </div>

          <div class="mt-6 uploader">
            <ImageUpload @media="setMedia" />
          </div>

          <div class="mt-4">
            <label for="details" class="block text-sm font-medium text-blue-800">
              Additional comments
            </label>
            <div class="mt-1">
              <textarea v-model="comments"
                rows="3"
                class="text-sm border border-guestio-gray-200 resize-none w-full rounded-xl px-4 py-3"
              />
            </div>
          </div>

          <div class="mt-6 flex justify-center">
            <button
              type="button"
              class="rounded-full bg-indigo-gradiant text-white py-3 font-bold flex justify-center items-center uppercase px-16 disabled:opacity-50"
              @click="select(close)"
              :disabled="!media.url"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/shared/Modal';
import XIcon from '@/components/svgs/XIcon';
import UploadArrowIcon from '@/components/svgs/UploadArrowIcon';
import ImageUpload from '@/components/shared/ImageUpload';

export default {
  components: {
    Modal,
    XIcon,
    UploadArrowIcon,
    ImageUpload,
  },

  data() {
    return {
      media: {},
      comments: '',
    }
  },

  methods: {
    select(close) {
      this.$emit('selected', {
        ...this.media,
        comments: this.comments,
      });

      close();
    },

    setMedia(media) {
      this.$set(this.media, 'url', media.url);
      this.$set(this.media, 'file_name', media.file_name);
      this.$set(this.media, 'size', media.size);
    },
  }
}
</script>

<style>
  .uploader .form-input {
    @apply bg-guestio-gray-100 border-violet rounded-xl border h-32;
  }

  .uploader .preview {
    @apply flex items-center justify-center;
  }
</style>
