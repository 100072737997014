<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium">Past Guests</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <div class="flex-1">
          <div v-if="! show.pastGuests.length">-</div>
          <div v-else class="space-y-4">
            <div v-for="guest in show.pastGuests.slice(0, visibleGuests)" :key="`guest-${guest.id}`" class="flex w-full items-center border-b border-gray-200 pb-4 last:border-0 last:pb-0">
              <div class="flex-shrink-0">
                <img
                  class="w-12 h-12 rounded-full flex-shrink-0 mr-4"
                  :src="guest.smallThumbUrl || 'https://www.gravatar.com/avatar/361cd2f9fbc932cf8e90d094fa62bd89?s=100&r=g&d=mp'"
                  :alt="guest.name"
                />
              </div>
              <div class="flex-1">
                <h4 class="font-semibold capitalize">{{guest.name}}</h4>
                <p class="text-sm text-pink-500">{{guest.title}}</p>
              </div>
            </div>

            <div v-if="show.pastGuests.length > visibleGuests">
              <button class="text-sm font-semibold text-pink-500" type="button" @click.prevent="edit = true">
                + {{ show.pastGuests.length - visibleGuests }} more
              </button>
            </div>
          </div>
        </div>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <div class="space-y-4">
          <div v-for="guest in show.pastGuests" :key="`guest-${guest.id}`" class="flex w-full items-center border-b border-gray-200 pb-4">
            <div class="flex w-full items-center justify-between">
              <div class="flex-shrink-0">
                <img
                  class="w-12 h-12 rounded-full flex-shrink-0 mr-4"
                  :src="guest.smallThumbUrl || 'https://www.gravatar.com/avatar/361cd2f9fbc932cf8e90d094fa62bd89?s=100&r=g&d=mp'"
                  :alt="guest.name"
                />
              </div>
              <div class="flex-1">
                <h4 class="font-semibold capitalize">{{guest.name}}</h4>
                <p class="text-sm text-pink-500">{{guest.title}}</p>
              </div>
            </div>

            <div>
              <alert-dialog
                class="w-full rounded-full py-3 text-center text-gray-500"
                confirm="Remove"
                @confirm="removeGuest($event, guest)"
              >
                <XIcon class="h-5" />
                <span slot="title">Remove past guest?</span>
                <template v-slot:content>
                  <div>Are you sure you want to remove this past guest?</div>
                </template>
              </alert-dialog>
            </div>
          </div>
          <div v-if="working" class="flex items-center justify-center py-2">
            <loading-icon class="h-4 w-4"/>
          </div>
        </div>
        <div class="mt-4">
          <div class="guest-search rounded-lg shadow-sm rounded-full">
            <label for="name" class="sr-only">Your Name</label>
            <autocomplete
              ref="autocomplete"
              :search="search"
              :debounce-time="500"
              :auto-select="true"
              @submit="addGuest"
              placeholder="Search for a guest"
              aria-label="Search for a guest"
            >
              <template #result="{ result, props }">
                <li class="flex w-full items-center justify-between" v-bind="props">
                  <div class="flex-shrink-0">
                    <img
                      class="w-10 h-10 rounded-full flex-shrink-0 mr-4"
                      :src="result.smallThumbUrl || 'https://www.gravatar.com/avatar/361cd2f9fbc932cf8e90d094fa62bd89?s=100&r=g&d=mp'"
                      :alt="result.name"
                    />
                  </div>
                  <div class="flex-1">
                    <h4 class="text-sm font-semibold capitalize">{{result.name}}</h4>
                    <p class="text-xs text-pink-500">{{result.title}}</p>
                  </div>
                </li>
              </template>
            </autocomplete>
          </div>
        </div>
        <div class="mt-4 flex items-center justify-end">
          <button @click.prevent="edit = false" type="button" class="detail-save-button">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import AlertDialog from '@/components/shared/AlertDialog'
  import Autocomplete from '@trevoreyre/autocomplete-vue'
  import Edit from "@/components/shared/Edit"
  import XIcon from "@/components/svgs/XIcon"
  import '@trevoreyre/autocomplete-vue/dist/style.css'

  export default {
    components: {AlertDialog, Edit, Autocomplete, XIcon},

    props: {
      show: Object,
    },

    data() {
      return {
        visibleGuests: 3,
        working: false,
        edit: false,
        form: {
          name: this.show.name,
        }
      }
    },

    watch: {
      edit(edit) {
        if (edit) return

        this.form.name = this.show.name
      }
    },

    mounted() {
      this.form.id = this.show.id;
    },

    methods: {
      search(query) {
        return new Promise(resolve => {
          if (query.length < 3) {
            return resolve([])
          }

          api.get('/guests', {
            params: {
              page: 1,
              limit: 5,
              'filter[guest_profiles.name]': query
            }
          }).then(({ data }) => {
            // filtering results to remove own profiles and profiles already linked
            let results = data.data.filter(guest => {
              return ! this.guestBelongsToThisUser(guest) && ! this.existsInPastGuests(guest)
            })
            resolve(results)
          }).catch(() => {
            resolve([])
          })
        })
      },

      addGuest(guest) {
        this.working = true
        this.$store.dispatch('shows/addPastGuest', {
          show_profile_id: this.show.id,
          guest_profile_id: guest.id,
        }).then(() => {
          this.show.pastGuests.push(guest)
        }).catch(error => {
          alert(error.response.data.message)
        }).finally(() => this.working = false)

        this.$refs.autocomplete.setValue('')
      },

      removeGuest(callback, guest) {
        this.working = true

        this.$store.dispatch('shows/removePastGuest', {
          show_profile_id: this.show.id,
          guest_profile_id: guest.id,
        }).then(() => {
          let index = this.show.pastGuests.findIndex(g => g.id == guest.id)
          this.show.pastGuests.splice(index, 1)

          callback.call()
        }).catch(error => {
          alert(error.response.data.message)
        }).finally(() => this.working = false)
      },

      guestBelongsToThisUser(guest) {
        return guest.user_id == this.show.user_id
      },

      existsInPastGuests(guest) {
        return this.show.pastGuests
          .map(g => g.id)
          .includes(guest.id)
      },
    }
  }
</script>
<style>
  .guest-search .autocomplete-input {
    @apply text-black bg-gray-50 pl-12 pr-5 py-2 rounded-full border-transparent text-sm w-full !important;
    background-size: 20px;
  }
  .guest-search .autocomplete-input:focus {
    @apply border border-purple-500 shadow-3xl !important;
  }
  .guest-search .autocomplete-result-list {
    @apply rounded-xl border border-gray-300 shadow-3xl !important;
  }
  .guest-search [data-position='below'] .autocomplete-result-list {
    @apply mt-1 pt-2 !important;
  }
  .guest-search [data-position='above'] .autocomplete-result-list {
    @apply mb-1 pb-2 !important;
  }
  .guest-search .autocomplete-result {
    @apply pl-4 !important;
    background-image: none !important;
  }
</style>
