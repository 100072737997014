<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-grayDark font-medium">Cover Image</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <MediaUploadSingle
        entity="show"
        :working="working"
        :modelId="show.id"
        :imageUrl="form.coverImageUrl"
        :error="uploadingError"
        @uploaded="uploaded"
        @deleted="deleted"
        allowedSize="10MB"
        suggestedDimensions="Suggested Dimensions: 285 x 1400"
      />
    </div>
  </div>
</template>

<script>
  import MediaUploadSingle from '@/components/shared/MediaUploadSingle';

  export default {
    props: {
      show: Object,
    },

    components: {
      MediaUploadSingle
    },

    data() {
      return {
        working: false,
        uploadingError: false,
        form: {
          coverImageUrl: this.show.coverImageUrl,
        }
      }
    },

    mounted() {
      this.form.id = this.show.id;
    },

    methods: {
      update() {
        this.working = true
        this.uploadingError = false

        this.$store.dispatch('shows/updateShow', this.form)
          .then(() => {
            this.working = false
          })
          .catch(() => {
            this.working = false
            this.uploadingError = true
          })
      },

      uploaded(url) {
        this.form.coverImageUrl = url
        this.update()
      },

      deleted() {
        this.form.coverImageUrl = null
      },
    }
  }
</script>
