<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <label class="text-grayDark font-medium" for="">Show Name</label>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <span class="font-semibold text-blue-800">{{ show.name }}</span>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="nameForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <ValidationProvider mode="lazy" rules="required|max:255" vid="name" name="Your name" v-slot="{ errors }">
                <div>
                  <div class="rounded-lg shadow-sm">
                    <label for="name" class="sr-only">Your Name</label>
                    <input name="name" id="name" type="text" class="guestio-form-input" v-model="form.name" placeholder="Your Name" />
                  </div>
                  <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import Edit from "@/components/shared/Edit";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    components: {Edit, SaveOrCancel},

    props: {
      show: Object,
    },

    data() {
      return {
        working: false,
        edit: false,
        form: {
          name: this.show.name,
        }
      }
    },

    watch: {
      edit(edit) {
        if (edit) return

        this.form.name = this.show.name
      }
    },

    mounted() {
      this.form.id = this.show.id;
    },

    methods: {
      update() {
        this.working = true

        this.$store.dispatch('shows/updateShow', this.form)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.nameForm.setErrors(error.response.data.errors)
            }
          })
      }
    }
  }
</script>
