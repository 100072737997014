<template>
  <div class="rounded-b-xl border border-gray-300 p-4" :class="roundedT">
    <div class="divide-y mb-8">
      <div v-if="currentPodcastStats.founded" class="grid grid-cols-2 py-3">
        <span>Founded</span>
        <span class="text-right font-semibold">
          {{ moment(currentPodcastStats.founded).format("YYYY") }}
        </span>
      </div>
      <div
        v-if="currentPodcastStats.num_episodes"
        class="grid grid-cols-2 py-3"
      >
        <span>Episodes</span>
        <span class="text-right font-semibold">{{
          currentPodcastStats.num_episodes
        }}</span>
      </div>
      <div v-if="currentPodcastStats.frequency" class="grid grid-cols-2 py-3">
        <span>Frequency</span>
        <span class="text-right font-semibold capitalize">{{
          currentPodcastStats.frequency
        }}</span>
      </div>
      <div
        v-if="currentPodcastStats.genres.length > 0"
        class="grid grid-cols-2 py-3"
      >
        <span>Categories</span>
        <span class="text-right font-semibold capitalize">{{
          currentPodcastStats.genres.join(" - ")
        }}</span>
      </div>
    </div>

    <div
      class="w-full bg-guestio-gray-100 uppercase text-blue-800 font-bold text-xs px-2.5 py-1 mb-4"
    >
      Audience
    </div>
    <div class="divide-y mb-10">
      <div
        v-if="currentPodcastStats.listeners_per_episode"
        class="grid grid-cols-2 py-3"
      >
        <span>Listeners p/ episode</span>
        <span class="text-right font-semibold capitalize">{{
          parseAmount(currentPodcastStats.listeners_per_episode)
        }}</span>
      </div>
      <div v-if="currentPodcastStats.engagement" class="grid grid-cols-2 py-3">
        <span>Engagement score</span>
        <span class="text-right font-semibold capitalize">{{
          currentPodcastStats.engagement
        }}</span>
      </div>
      <div
        v-if="currentPodcastStats.primary_location"
        class="grid grid-cols-2 py-3"
      >
        <span>Primary location</span>
        <span class="text-right font-semibold capitalize">{{
          currentPodcastStats.primary_location
        }}</span>
      </div>
    </div>

    <div
      class="w-full bg-guestio-gray-100 uppercase text-blue-800 font-bold text-xs px-2.5 py-1 mb-4"
    >
      Social media followers
    </div>
    <div class="divide-y mb-10">
      <div
        v-for="(amount, socialNetwork) in currentPodcastStats.followers"
        :key="socialNetwork"
      >
        <div class="grid grid-cols-2 space-y-3">
          <span class="capitalize">{{ socialNetwork }}</span>
          <span class="text-right font-semibold capitalize">{{
            parseAmount(amount)
          }}</span>
        </div>
      </div>
    </div>

    <div
      v-if="currentPodcastStats.related"
      class="w-full bg-guestio-gray-100 uppercase text-blue-800 font-bold text-xs px-2.5 py-1 mb-4"
    >
      Related shows
    </div>
    <div v-if="currentPodcastStats.related" class="mb-10">
      <div
        v-for="(relatedPodcast, index) in currentPodcastStats.related.slice(
          0,
          5
        )"
        :key="index"
      >
        <div class="grid grid-cols-2 py-1">
          <span class="capitalize">{{ relatedPodcast }}</span>
        </div>
      </div>
    </div>

    <div
      class="w-full bg-guestio-gray-100 uppercase text-blue-800 font-bold text-xs px-2.5 py-1 mb-4"
    >
      Feedback
    </div>
    <div class="divide-y mb-3">
      <div
        v-if="currentPodcastStats.average_rating"
        class="grid grid-cols-2 space-y-3"
      >
        <span>Ratings across shows</span>
        <div class="flex justify-end items-end">
          <ProfileRating
            :rating="currentPodcastStats.average_rating"
            activeColor="#B503D0"
            inactiveColor="#D4D4D4"
            :starSize="16"
          />
          <span
            class="ml-4 text-right leading-tight font-semibold capitalize"
            >{{ parseAmount(currentPodcastStats.ratings_count) }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { nFormatter } from '@/utils/helpers';
import ProfileRating from "@/components/shared/ProfileRating";

export default {
  name: "RephonicStats",
  components: { ProfileRating },
  data() {
    return {
      moment: moment,
    }
  },
  props: {
    roundedT: { type: String, default: 'rounded-t-xl' },
    currentPodcastStats: { type: Object, required: true }
  },

  methods: {
    parseAmount(amount) {
      return nFormatter(amount);
    },
  },
};
</script>
